.custom-table {
    table-layout: fixed;
    width: 100%;
}

.custom-table tbody {
    display: block;
    height: calc(35vh - 56px); /* Adjust the height accordingly */
    overflow: auto;
}

.custom-table thead,
.custom-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
